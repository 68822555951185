import React from "react";
import About from "../../components/About";
import Contact from "../../components/Contact";

function AboutPage() {
  return (
    <>
      <About />
      <Contact />
    </>
  );
}

export default AboutPage;
