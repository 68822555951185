import React from "react";
import PDF from "../../components/PDF";

function Resume() {
  return (
      <PDF />
  );
}

export default Resume;
