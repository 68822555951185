import React from "react";
import Contact from "../../components/Contact";

function ContactPage() {
  return (
    <>
      <Contact />
    </>
  );
}

export default ContactPage;
